import {defineStore} from "pinia";
import {useCookieStore} from "~/store/cookie";

export const DESIGN_VERSION = 4; // version of the design system so we know if certain features have been enabled
// VERSION 4 ADDED EXTRA SUBTITLE OPTIONS
export const useEditorStore = defineStore({
  id: 'editor-store',
  state: () => {
    return {
      name: null,
      videoSize: null,
      design: null,
      savingDesign: false,
      designPage: null,
      backgroundVideoGroupPage: null,
      backgroundVideoPage: null,
      fetchError: false,
      imageGenError: false,
      zoom: 1,
      canvasX: 0,
      canvasY: 0,
      steps: [],
      images: [],
      addText: false,
      addProgress: false,
      addWaveform: false,
      addSubtitles: false,
      selectedItem: null,
      progressColor: 'rgb(255,255, 255)',
      progressColor2: 'rgb(255,255, 255)',
      waveformColor: 'rgb(255,255, 255)',
      waveformColor2: 'rgb(255,255, 255)',
      waveformColor3: 'rgb(255,255, 255)',
      waveformColor4: 'rgb(255,255, 255)',
      backgroundColor: 'rgba(16,29,45,255)',
      fonts: [],
      generatedImages: [],
      pixabayImagePage: null,
      pixabayImages: [],
      pixabayVideoPage: null,
      pixabayVideos: [],
      upscaledImage: null,
      loadedFonts: [],
      designNotFoundError: false,
      copyError: false,
      updateError: false,
      savingDesignError: false,
      errorMessage: null,
      noImageTokensError: false,
      userVideoAdded:false, // keep track of whether user has added a video to the canvas
      designs: [],
      templates: [], // keep track of templates list
    }
  },

  actions: {
    async createDesign(name, size, config) {
     const cookieStore = useCookieStore();
     this.fetchError = false;
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/custom-designs/create/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: {name: name, video_size: size}
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });


      if (data.value) {
        this.design = data.value;
        // reset editor
        this.progressColor = 'rgb(255,255, 255)',
        this.waveformColor = 'rgb(255,255, 255)',
        this.backgroundColor = 'rgba(16,29,45,255)'
      }
      if (error.value) {
        this.fetchError = true;
      }
    },
    async copyDesign(key, newSize, config) {
      const cookieStore = useCookieStore();
      this.copyError = false;
      let params = {};
      if (newSize) {
        params['size'] = newSize;
      }
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/custom-designs/${key}/copy/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: params
      }).catch((error) => {
        console.log(error);
        this.copyError = true;
      });

      if (data.value) {
        this.design = data.value.design;
      }
      if (error.value) {
        this.copyError = true;
      }
    },
    async updateDesignDetails(key, name, config) {
      const cookieStore = useCookieStore();
      this.fetchError = false;
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/custom-designs/${key}/details/`, {
        method: 'PUT',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: {name: name}
      }).catch((error) => {
        this.fetchError = true;
      });

      if (data.value) {
        return data.value.name;
      }
      if (error.value) {
        this.fetchError = true;
      }
    },
    async downloadDesignImage(key, format, config) {
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/custom-designs/image/${key}/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: {
          format: format
        }
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        let extension = format;
        if (format === 'jpeg') {
          extension = 'jpg'
        }
         // download file to browser
        const url = window.URL.createObjectURL(new Blob([data.value]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `design-image-${key}.${extension}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    },
    async customiseTemplate(slug, inputsMap, config) {
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/customise-template/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        body: {slug: slug, input_data: inputsMap}
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        this.design = data.value.design;
      }
    },
    async getDesign(key, config) {
     const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/custom-designs/${key}/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        this.design = data.value;
      }
      if (error.value) {
        if (error.value.statusCode === 404) {
          this.designNotFoundError = true;
        }
        else {
         this.fetchError = true;
        }
      }
    },
    async getDesigns(page, sizeId, config) {
      let params = {}
      if (page) {
        params['page'] = page
      }
      if (sizeId) {
        params['video_size'] = sizeId
      }
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/custom-designs/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: params
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (resp) {
        this.designPage = resp;
      }
      else {
        this.fetchError = true;
      }
    },
    async deleteDesign(key, config) {
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/custom-designs/${key}/`, {
        method: 'DELETE',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });
    },
    async getBackgroundVideos(page, video_size_id, config) {
     let params = {}
      if (page) {
        params['page'] = page;
        params['video_size'] = video_size_id
      }
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/background-videos/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: params
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        this.backgroundVideoPage = data.value;
      }
    },
    async getBackgroundVideoGroups(page, config) {
     let params = {}
      if (page) {
        params['page'] = page
      }
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/background-video-groups/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: params
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        this.backgroundVideoGroupPage = data.value;
      }
    },
    async getFonts(config) {
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/fonts/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        this.fonts = data.value.results;
      }
    },
    async pixabaySearchImages(query, page, video_size, category, language_code, config) {
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/pixabay/search/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: {
          query: query,
          page: page,
          size: video_size,
          category: category,
          language_code: language_code
        }
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value && data.value.hits) {
        this.pixabayImages.push(...data.value.hits);
      }
    },
    async pixabayImportImage(image_id, config) {
      this.upscaledImage = null;
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/pixabay/${image_id}/import/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        console.log(data.value)
        this.upscaledImage = data.value;
      }
    },
    async pixabaySearchVideos(query, page, video_size, category, language_code, config) {
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/pixabay/video/search/`, {
        method: 'GET',
        headers: {'Authorization': 'Token ' + cookieStore.token},
        params: {
          query: query,
          page: page,
          size: video_size,
          category: category,
          language_code: language_code
        }
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value && data.value.hits) {
        this.pixabayVideos.push(...data.value.hits);
      }
    },
    async pixabayImportVideo(video_id, config) {
      this.upscaledImage = null;
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch(config.public.externalApiBase + `/api/pixabay/video/${video_id}/import/`, {
        method: 'POST',
        headers: {'Authorization': 'Token ' + cookieStore.token},
      }).catch((error) => {
        console.log(error);
        this.fetchError = true;
      });

      if (data.value) {
        console.log(data.value)
        this.upscaledImage = data.value;
      }
    },
    async generateImages(prompt, size, model, config) {
      this.imageGenError = false;
      this.noImageTokensError = false;
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/generate-images/`, {
        method: 'POST',
        server: false,
        body: {
          prompt: prompt,
          size: size,
          model: model
        },
        headers: {'Authorization': 'Token ' + cookieStore.token}
      }).catch((error) => {
        if (!error.response) {
          this.imageGenError = true;
          return;
        }
        if (error.response.status === 422) {
          this.noImageTokensError = true;
        }
        else {
          this.imageGenError = true;
        }

      });
      if (resp) {
        this.generatedImages = resp;
      } else {
       this.generatedImages = [];
      }

    },
    async upscaleImage(file_upload_id, size, config) {
      const cookieStore = useCookieStore();
      this.imageGenError = false;
      this.noImageTokensError = false;
      const resp = await $fetch(config.public.externalApiBase + `/api/upscale-image/`, {
        method: 'POST',
        server: false,
        body: {
          file_upload_id: file_upload_id,
          size: size,
        },
        headers: {'Authorization': 'Token ' + cookieStore.token}
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          this.noImageTokensError = true;
        }
        else {
          this.imageGenError = true;
        }
        console.log(error.response);
      });

      this.upscaledImage = resp;
    },
    async uploadDesignImages(partialImage, fullImage, imageFileName, design_key,
                             layout_json, audio_file_segment, zoom_level, config) {
      this.savingDesign = true;
      this.savingDesignError = false;
      let formData = new FormData();
      formData.append('name', imageFileName);
      formData.append('files', fullImage);
      if (partialImage){
        formData.append('files', partialImage);
      }

      if (audio_file_segment) {
        formData.append('audio_file_segment', audio_file_segment);
      }

      formData.append('template_data', layout_json)

      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/upload/`, {
        method: 'PUT',
        server: false,
        body: formData,
        params: {
          upload_type: 'DesignImage',
          design_key: design_key,
          design_version: DESIGN_VERSION,
          zoom_level: zoom_level
        },
        headers: {'Authorization': 'Token ' + cookieStore.token}
      }).catch((error) => {
        this.savingDesignError = true;
        this.errorMessage = 'error.something.went.wrong';
        if (!error.response) {
          return;
        }

        if (error.response.status === 415) {
          this.errorMessage = 'unsupported.file.type.image';
        }
        else if (error.response._data) {
          if (error.response._data.error === 'no template data') {
            this.errorMessage = 'error.saving.design';
          }
          else if (error.response._data.error === 'template data too long') {
            this.errorMessage = 'design.template.data.too.long';
          }
        }
      })

      this.uploadData = resp;
      this.savingDesign = false;
    },
    async updateDesignSize(key, size, config) {
      this.updateError = false;
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/custom-designs/${key}/resize/`, {
        method: 'POST',
        server: false,
        body: {
          size: size,
        },
        headers: {'Authorization': 'Token ' + cookieStore.token}
      }).catch((error) => {
        this.updateError = true;
      });

      if (resp) {
        this.design = resp.design;
      }
    }
  }
});